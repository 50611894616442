
.contact-container {
    padding: 56px;
    margin: 32px 0;
}
@media screen and (max-width: 1024px) {
    .contact-content {
        flex-direction: column;
    }
    .map-img {
        width: 100%
    }
    .contact-img {
        width: 100%;
        padding-top: 30px;
    }
}
@media screen and (max-width: 768px) {
 .contact-container {
    margin: 0px;
 }
}
@media screen and (max-width: 640px) {
    .contact-container {
       padding: 30px 20px;
    }
   }