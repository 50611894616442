.footer-logo-img {
    width: 8%;
}
.footer-container {
    padding: 40px 136px 0 56px;
}
.footer-content-drs {
    margin-left: 40px;
}
.item-srv-title {
    margin-top: 55px;
}

@media screen and (max-width: 1024px) {
    .footer-logo-img {
        width: 12%;
    }
    .footer-container {
        padding: 40px 40px 0 40px;
    }
    .footer-content-drs {
        margin-left: 20px;
    }
}
@media screen and (max-width: 768px) {
    .footer-logo-img {
        width: 8%;
    }
    .footer-container {
        flex-direction: column;
        gap: 0;
    }
    .footer-content-drs {
        margin-left: 0px;
    }
    .item-srv-title {
        margin-top: 0px;
    }
}
@media screen and (max-width: 640px) {
    .footer-logo-img {
        width: 17%;
    }
    .footer-container {
        padding: 40px 30px 0 30px;
    }
}