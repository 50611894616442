.recruit-title {
    text-align: center;
    padding-top: 91px;
    height: 50%;
}

.br-service {
    flex: 1;
    height: 2px;
    opacity: .1;
    background-color: currentColor;
}

.recruit-container {
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-around;
    padding-top: 50px;
}

.recr-container {
    display: flex;
}

@media screen and (max-width: 1200px) {
    .recruit-container {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .recruit-title {
        padding-top: 0;
    }

}

@media screen and (max-width: 768px) {
    .recruit-container {
        grid-template-columns: 80%;
    }


}

@media screen and (max-width: 640px) {


    .recr-container {
        flex-direction: column;
        align-items: start;
        gap: 13px;
        padding-bottom: 25px;
    }

    .item-bt {
        margin-top: 10px;
    }
}