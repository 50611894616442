.content-about {
    display: flex;
    background-image: url('/public/image/bg-about3.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    min-width: 100%;
    /* height: 675px; */
    /* background: #f7f7f7; */
}

.content-about-left {
    width: 50%;
    padding: 70px 50px 60px 96px;
}

.content-about-right {
    width: 50%;
    padding: 70px 100px 90px 15px;
}

.video-tvc {
    box-shadow: -5px 7px 5px grey;
}

@media screen and (max-width: 1024px) {
    .content-about {
        /* height: 515px; */
    }

    .content-about-left {
        padding: 70px 50px 60px 50px;
    }

    .content-about-right {
        padding: 96px 50px 90px 15px;
    }
}

@media screen and (max-width: 768px) {

    .content-about {
        flex-direction: column;
        /* height: 590px; */
    }

    .content-about-left {
        width: 100%;
    }

    .content-about-right {
        width: 100%;
        padding: 0 50px 50px;
    }
}

@media screen and (max-width: 640px) {


    .content-about-left {
        padding: 70px 30px 30px 30px;
    }

    .content-about-right {
        padding: 0 30px 50px;
    }

}