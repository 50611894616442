@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900"); */
* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d11812;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(97, 5, 5, 0.881);
    transition: width 2s linear 1s;
}