.services-container {
    background-image: url('../../../public//image/rectag-service2.png');
    background-repeat: repeat;
    background-size: cover;
    margin-bottom: 40px;
}

.services-title {
    text-align: center;
    padding-top: 91px;
    height: 50%;
}

.services-content {
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: center;
    padding-top: 50px;
}

.br-service {
    flex: 1;
    height: 2px;
    opacity: .1;
    background-color: currentColor;
}

.services-content-item {
    padding-bottom: 50px;
    margin-bottom: 70px;
}

.services-content-prod {
    display: grid;
    grid-template-columns: 23% 23% 23%;
    width: 80%;
    margin: auto;
    justify-content: center;
    gap: 1%;
    padding-top: 25px;
    padding-bottom: 80px
}

.cart {
    /* position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box; */
    /* border: 1px solid rgba(0,0,0,.125); */
    border-radius: 0.25rem;
    box-shadow: 0 1px 3px grey;
    background: #fff;
}

.face {
    transition: 0.5s;
}

.face.face1 {
    position: relative;
    background-color: white;
    justify-content: center;
    z-index: 1;
    border: none;
}

.face.face2 {
    position: relative;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
    box-shadow: 0 3px 3px grey;
    transform: translateY(-160px);
}

.cart:hover {
    transform: translateY(0);
}

.cart:hover .face.face2 {
    transform: translateY(0);
}

/* .cart :hover .face.face1 .content{
    transition: 0.5s;
    border: none;
}  */

.content-cart-up>h3 {
    color: #555555;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;

}

.bt-item-service {
    cursor: pointer;

}

.img-item-sevice:hover {
    animation-name: itemSevice;
    animation-duration: 1s;
}

@keyframes itemSevice {
    0% {
        top: 0px;
    }

    50% {
        top: -20px;
    }

    100% {
        top: 0px;
    }
}

.services-prod-title {
    margin-left: 327px;

}

@media screen and (max-width: 1200px) {
    .services-prod-title {
        margin-left: 21%;
    }

    .services-content {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .services-content {
        width: 95%;
    }

    .services-content-prod {
        padding-bottom: 35px;
        width: 95%;
    }

    .services-prod-title {
        margin-left: 16%;
    }
}

@media screen and (max-width: 768px) {
    .services-content {
        width: 80%;
        flex-direction: column;
        padding-bottom: 50px;
        padding-top: 0px;
    }

    .services-content-item {
        padding-bottom: 0px;
        margin-bottom: 0px;
        width: 100%;
    }

    .services-content-prod {
        width: 80%;
        grid-template-columns: 50% 50%;
        gap: 3%;
    }

    .services-prod-title {
        margin-left: 8%;
    }

}

@media screen and (max-width: 640px) {
    .services-content {
        width: 100%;
        padding-bottom: 0px;
    }

    .services-content-item {
        margin-top: 30px;
        margin-bottom: 10px
    }

    .services-prod-title {
        margin-left: 20px;
    }

    .services-content-prod {
        width: 95%;
        gap: 2%;
    }

    .face.face2 {
        padding: 10px 10px;
        font-size: 13px;
    }
}