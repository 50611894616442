.navbar-container {
    display: flex;
    grid-template-columns: 55% 45%;
}

.hamburger {
    display: none;
}

.shdw {
    box-shadow: 1px 2px 2px #bfb3b3;
}

@media screen and (max-width: 1024px) {
    .img-logo {
        width: 20%
    }

    .navbar-container {
        display: grid;
        grid-template-columns: 42% 58%;
        align-content: center;
        background: #ffd2cc;
        height: 98px;
    }

    .active {
        padding-left: 7%;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        background: #ffd2cc;
        position: absolute;
        /* top: 100%; */
        left: 0%;
        z-index: 1;
    }

    .nav-menu {
        display: none;
    }

    .hamburger {
        display: inline;
        height: 100%;
        padding-top: 20px;
    }

    .menu-container-up {
        display: none;
    }

}

@media screen and (max-width: 768px) {
    .img-logo {
        width: 25%
    }

    .navbar-container {
        grid-template-columns: 40% 60%;
    }
}

@media screen and (max-width: 640px) {
    .img-logo {
        width: 43%
    }

    .navbar-container {
        grid-template-columns: 35% 65%;
    }

    .navbar-h {
        height: 70px;
    }
}